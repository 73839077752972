<template>
  <div v-if="isOpen" class="lightbox" @click="close">
    <div class="lightbox-content" @click.stop>
      <button class="lightbox-close" @click="close">
        <img src="@/assets/img/close-blue.svg" alt="Fechar" />
      </button>
      <div v-if="isImage" class="lightbox-image">
        <img :src="content" alt="Imagem" />
      </div>
      <div v-else class="lightbox-video">
        <iframe
          v-if="videoPlatform === 'youtube'"
          :src="getYouTubeEmbedUrl(content)"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
        <iframe
          v-if="videoPlatform === 'vimeo'"
          :src="getVimeoEmbedUrl(content)"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
        ></iframe>
        <iframe
          v-if="videoPlatform === 'tiktok'"
          :src="getTikTokEmbedUrl(content)"
          frameborder="0"
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture"
          allowfullscreen
          class="vertical"
        ></iframe>
        <iframe
          v-if="videoPlatform === 'facebook'"
          :src="getFacebookEmbedUrl(content)"
          frameborder="0"
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      required: true,
    },
    isImage: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isOpen: true,
      videoPlatform: null,
    };
  },
  watch: {
    content: {
      immediate: true,
      handler(newValue) {
        this.detectPlatform(newValue);
      },
    },
  },
  methods: {
    close() {
      this.isOpen = false;
      this.$emit("close");
    },
    detectPlatform(url) {
      if (this.youtubeCheck(url)) {
        this.videoPlatform = "youtube";
      } else if (this.vimeoCheck(url)) {
        this.videoPlatform = "vimeo";
      } else if (this.tiktokCheck(url)) {
        this.videoPlatform = "tiktok";
      } else if (this.facebookCheck(url)) {
        this.videoPlatform = "facebook";
      } else {
        this.videoPlatform = null;
      }
    },
    youtubeCheck(url) {
      const youtubeRegex =
        // eslint-disable-next-line no-useless-escape
        /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
      return youtubeRegex.test(url);
    },
    getYouTubeEmbedUrl(url) {
      const videoId = url.match(
        // eslint-disable-next-line no-useless-escape
        /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
      )[1];
      return `https://www.youtube.com/embed/${videoId}`;
    },
    vimeoCheck(url) {
      const vimeoRegex = /(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(\d+)/;
      return vimeoRegex.test(url);
    },
    getVimeoEmbedUrl(url) {
      const videoId = url.match(/vimeo\.com\/(\d+)/)[1];
      return `https://player.vimeo.com/video/${videoId}`;
    },
    tiktokCheck(url) {
      const tiktokRegex =
        /(?:https?:\/\/)?(?:www\.)?tiktok\.com\/(@[\w.-]+)\/video\/(\d+)/;
      return tiktokRegex.test(url);
    },
    getTikTokEmbedUrl(url) {
      const match = url.match(/tiktok\.com\/(@[\w.-]+)\/video\/(\d+)/);
      const videoId = match ? match[2] : null;
      return `https://www.tiktok.com/player/v1/${videoId}`;
    },
    facebookCheck(url) {
      const facebookRegex =
        /(?:https?:\/\/)?(?:www\.)?facebook\.com\/.*\/videos\/(\d+)/;
      return facebookRegex.test(url);
    },
    getFacebookEmbedUrl(url) {
      const encodedUrl = encodeURIComponent(url);
      return `https://www.facebook.com/plugins/video.php?href=${encodedUrl}`;
    },
  },
};
</script>

<style scoped lang="scss">
.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 150px 0 80px;
  overflow: scroll;
  &-content {
    position: relative;
    max-width: 90%;
    max-height: calc(90vh - 50px);
  }
  &-close {
    position: absolute;
    top: 0;
    right: 0;
    background: none;
    border: none;
    cursor: pointer;
    transform: translateY(calc(-100% - 14px));
    img {
      width: 44px;
      height: 44px;
      transition: 0.3s ease-in-out;
    }

    @include media(">=desktop") {
      &:hover,
      &:focus {
        img {
          transform: rotate(-180deg);
        }
      }
    }
  }
  &-image img {
    max-width: 100%;
    max-height: 90vh;
    object-fit: cover;
  }

  &-video {
    max-width: 90vw;
    @include media(">=desktop") {
      max-width: 60vw;
    }
    video,
    iframe {
      aspect-ratio: 16/9;
      width: 90vw;
      @include media(">=desktop") {
        width: 60vw;
      }
      &.vertical {
        aspect-ratio: 9/16;
        max-width: 400px;
        width: 100%;
        @include media(">=desktop") {
          max-height: 60vh;
        }
      }
    }
  }
}
</style>
