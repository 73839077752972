<template>
  <div
    :class="['dropdown', { active: actived }]"
    @click="toggleDropdown"
    ref="dropdown"
  >
    <div class="dropdown__selected">
      <p>{{ current ? current.name : val }}</p>
      <span></span>
    </div>
    <ul class="dropdown__options">
      <li v-for="item in items" :key="item.value" @click.stop="choice(item)">
        {{ item.name }}
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["items", "val", "dropdownKey"],
  data() {
    return {
      actived: false,
    };
  },
  computed: {
    ...mapState({
      current(state) {
        return state.dropdowns[this.dropdownKey];
      },
    }),
  },
  methods: {
    toggleDropdown() {
      this.actived = !this.actived;
    },
    choice(item) {
      this.$store.dispatch("updateDropdown", {
        dropdown: this.dropdownKey,
        value: item,
      });
      this.actived = false;

      this.$emit("selection-changed", item);
    },
    handleClickOutside(event) {
      if (this.$refs.dropdown && !this.$refs.dropdown.contains(event.target)) {
        this.actived = false;
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style scope lang="scss">
.dropdown {
  position: relative;
  &__selected {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border: 2px solid color(black);
    background: color(white);
    padding: 9px 20px;
    cursor: pointer;
    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 3px;
      left: 3px;
      background: color(black);
      z-index: -1;
    }
    p {
      font-size: 1em;
      line-height: 1.4em;
      @include media(">=desktop") {
        font-size: 0.7em;
      }
    }
    span {
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 6px solid #000;
      transition: 0.3s ease-in-out;
    }
  }
  &__options {
    max-height: 0px;
    background: color(white);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    transition: max-height 0.3s ease-in-out;
    overflow: scroll;
    border: 2px solid color(black);
    border-top: initial;
    list-style: none;
    transform: translateY(calc(100% - 2px));
    li {
      padding: 5px 20px;
      cursor: pointer;
      @include media(">=desktop") {
        font-size: 0.8em;
      }
      &:hover {
        @include media(">=desktop") {
          background: #fbfbe4;
        }
      }
    }
  }
  &.active {
    z-index: 3;
    .dropdown__selected {
      span {
        transform: rotate(180deg);
      }
    }
    .dropdown__options {
      max-height: 250px;
      padding: 0 0 20px;
    }
  }
}
</style>
