import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// import VueSmoothScroll from "vue3-smooth-scroll";
import Vue3Marquee from "vue3-marquee";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import { ObserveVisibility } from "vue-observe-visibility";

const app = createApp(App);
app.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBlzckztVyVfZLDTvWT1DSyhUZLkgtaGmE",
  },
});
app.use(Vue3Marquee);
app.use(store);
app.use(router);

// app.use(VueSmoothScroll, {
//   duration: 800,
//   offset: -50,
//   easing: "ease-in-out",
// });

app.directive("observe-visibility", ObserveVisibility);

app.mount("#app");
