<template>
  <section class="modal-project-item" v-if="item">
    <div class="modal-project-item__wrapper">
      <figure>
        <span class="icon">
          <img
            src="@/assets/img/icon-image.svg"
            alt=""
            v-if="!item.videos.length"
          />
          <img src="@/assets/img/icon-play.svg" alt="" v-else />
        </span>
        <img
          :src="getYouTubeEmbedAndThumb(item.videos[0].link).thumbUrl"
          alt=""
          v-if="videoPlatform == 'youtube'"
        />
        <img
          :src="getThumbVimeo(item.videos[0].link)"
          alt=""
          v-else-if="videoPlatform == 'vimeo'"
        />
        <img
          :src="tiktokThumbnail"
          alt=""
          v-else-if="videoPlatform == 'tiktok'"
        />
        <img :src="item.images[0].path" alt="" v-else />
      </figure>
      <h3>{{ item.title }}</h3>
    </div>
    <div class="modal-project-item__content">
      <p>{{ item.schools.map((school) => school.name).join(", ") }}</p>
      <p>{{ item.school_degree }}</p>
      <p>{{ item.biome }}</p>
      <p>
        <strong>{{ item.state }} - {{ item.city }}</strong>
      </p>
    </div>
  </section>
</template>

<script>
export default {
  props: ["item"],
  data() {
    return {
      videoPlatform: null,
      tiktokThumbnail: null,
    };
  },
  watch: {
    item: {
      immediate: true,
      handler(newValue) {
        if (newValue.videos.length) {
          this.detectPlatform(newValue.videos[0].link);
        }
      },
    },
  },
  methods: {
    detectPlatform(url) {
      if (this.youtubeCheck(url)) {
        this.videoPlatform = "youtube";
      } else if (this.vimeoCheck(url)) {
        this.videoPlatform = "vimeo";
      } else if (this.tiktokCheck(url)) {
        this.videoPlatform = "tiktok";
        this.loadTikTokThumbnail(url);
      } else if (this.facebookCheck(url)) {
        this.videoPlatform = "facebook";
      } else {
        this.videoPlatform = null;
      }
    },
    youtubeCheck(url) {
      const youtubeRegex =
        // eslint-disable-next-line no-useless-escape
        /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
      return youtubeRegex.test(url);
    },
    vimeoCheck(url) {
      const vimeoRegex = /(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(\d+)/;
      return vimeoRegex.test(url);
    },
    getVimeoEmbedUrl(url) {
      const videoId = url.match(/vimeo\.com\/(\d+)/)[1];
      return `https://player.vimeo.com/video/${videoId}`;
    },
    tiktokCheck(url) {
      const tiktokRegex =
        /(?:https?:\/\/)?(?:www\.)?tiktok\.com\/(@[\w.-]+)\/video\/(\d+)/;
      return tiktokRegex.test(url);
    },
    getTikTokEmbedUrl(url) {
      const match = url.match(/tiktok\.com\/(@[\w.-]+)\/video\/(\d+)/);
      const videoId = match ? match[2] : null;
      return `https://www.tiktok.com/embed/${videoId}`;
    },
    getThumbVimeo(url) {
      const videoId = url.match(/vimeo\.com\/(\d+)/)[1];
      return `https://vumbnail.com/${videoId}.jpg`;
    },
    getYouTubeEmbedAndThumb(url) {
      const videoId = url.match(
        // eslint-disable-next-line no-useless-escape
        /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
      )[1];
      if (videoId) {
        const embedUrl = `https://www.youtube.com/embed/${videoId}`;
        const thumbUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;

        return { embedUrl, thumbUrl };
      }
      return null;
    },

    async loadTikTokThumbnail(url) {
      this.tiktokThumbnail = await this.getTikTokThumbnail(url);
    },
    async getTikTokThumbnail(videoUrl) {
      const oembedUrl = `https://www.tiktok.com/oembed?url=${encodeURIComponent(
        videoUrl
      )}`;

      try {
        const response = await fetch(oembedUrl);
        const data = await response.json();

        if (data.thumbnail_url) {
          return data.thumbnail_url;
        } else {
          return this.project.images[0].path;
        }
      } catch (error) {
        console.error("Erro ao pegar thumbnail do TikTok:", error);
        return null;
      }
    },
    facebookCheck(url) {
      const facebookRegex =
        /(?:https?:\/\/)?(?:www\.)?facebook\.com\/.*\/videos\/(\d+)/;
      return facebookRegex.test(url);
    },
  },
};
</script>

<style scoped lang="scss">
.modal-project-item {
  padding: 10px 10px 0;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  @include media(">=desktop") {
    padding: 15px 15px 0;
  }
  &:hover {
    @include media(">=desktop") {
      background: color(hover);
    }
  }
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    figure {
      width: 44%;
      position: relative;
      .icon {
        position: absolute;
        top: 5px;
        left: 5px;
        max-width: 12px;
        @include media(">=desktop") {
          top: 10px;
          left: 10px;
          max-width: 20px;
        }
        img {
          aspect-ratio: initial !important;
        }
      }
      img {
        width: 100%;
        aspect-ratio: 117/95;
        object-fit: cover;
      }
    }
    h3 {
      font-size: 0.864em;
    }
  }
  &__content {
    margin-top: 10px;
    padding-bottom: 13px;
    border-bottom: 1px solid color(black);
    p {
      font-size: 0.727em;
    }
  }
}
</style>
